import React, { createContext, useState } from "react"

const defaultTheme = ["#274872", "#DBF5B0"]

export const ThemeContext = createContext({
  theme: defaultTheme,
  setTheme: () => {},
})

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(defaultTheme)

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

const ThemeProviderModule = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
)

export default ThemeProviderModule
